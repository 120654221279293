import Link from 'next/link'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import { Button, LazyImage } from '@electro/shared-ui-components'
import { ProductPositioningStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '../../helpers/getStoryBlokImageDimensions'

interface CentralPositioningSectionProps {
  blok: ProductPositioningStoryblok
}

const MAX_IMAGE_WIDTH = 391

export const CentralPositioningSection = ({ blok }: CentralPositioningSectionProps) => {
  const dimensions = getStoryBlokImageDimensions(blok.image.filename)
  const constrainedDimensions =
    dimensions?.width > MAX_IMAGE_WIDTH
      ? {
          width: MAX_IMAGE_WIDTH,
          height: dimensions.height / (dimensions.width / MAX_IMAGE_WIDTH),
        }
      : dimensions

  return (
    <section
      data-testid="central-positioning"
      className="max-w-6xl w-full mx-auto px-2 sm:pt-12 sm:pb-12 sm:grid grid-cols-12"
    >
      <div className="sm:col-start-1 sm:col-end-6 mb-8 sm:mb-0 sm:w-full w-60 mx-auto sm:pr-16 relative pt-8 pb-8">
        {/* Display only elements for bg shapes */}
        <div
          data-test-id="framing-rect"
          aria-hidden="true"
          style={{ height: '230%' }} // Custom height for this element only.
          className="bg-white bg-opacity-30 absolute pointer-events-none w-screen md:-bottom-16 md:right-32 rounded-4xl z-0 hidden md:block"
        />
        <div
          data-test-id="gradient-egg"
          aria-hidden="true"
          className="bg-logo-cloud absolute pointer-events-none h-screen w-full bg-contain bg-no-repeat sm:-top-16 sm:-left-8 z-0"
        />
        <LazyImage
          className="relative z-10"
          src={`${getCmsAssetUrl(blok.image.filename)}/m/782x0`}
          alt={blok.image.alt}
          width={constrainedDimensions.width}
          height={constrainedDimensions.height}
        />
      </div>
      <div className="sm:col-start-6 sm:col-end-13 flex flex-col justify-center relative z-10">
        <div className="flex flex-col sm:items-start ">
          <div className="flex-grow">{render(blok.body)}</div>
          <Link href="/map" className="no-underline">
            <Button size="lg" variant="raised" className="mt-6">
              {blok.buttonText}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  )
}
