import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import Image from 'next/image'

import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'
import { Typography } from '@electro/shared-ui-components'
import {
  CtaButtonStoryblok,
  StatsHeroStoryblok,
} from '@electro/consumersite/types/generated/storyblok'
import { DynamicComponent } from '@electro/consumersite/src/storyblok/DynamicComponent'

interface HeroProps {
  blok: StatsHeroStoryblok
}

const styles = {
  headline: {
    'bold-pink-uppercase':
      'relative z-0 !text-primary uppercase text-3xl sm:text-4xl lg:text-6xl xl:text-8xl',
    'bold-white-uppercase':
      'relative z-0 !text-white uppercase text-3xl sm:text-4xl lg:text-6xl xl:text-8xl',
    'large-white-sentence': 'relative z-0 !text-white normal-case text-3xl sm:text-4xl lg:text-7xl',
  },
}

export const LandingPageHero = ({ blok }: HeroProps) => {
  const imageDimensions = blok?.image?.filename
    ? getStoryBlokImageDimensions(blok?.image?.filename)
    : null

  return (
    <section
      data-testid="landing-page-main-banner"
      className="flex-col max-w-3xl mx-auto flex items-center gap-4 my-16 px-8 md:my-32 lg:flex-row lg:max-w-6xl xl:max-w-7xl"
    >
      <div className="order-2 lg:order-1 max-w-2xl">
        {blok.Headline ? (
          <Typography variant="display" as="h1" className={styles.headline[blok.headlineStyle]}>
            {blok.Headline}
          </Typography>
        ) : null}

        {blok.content ? (
          <Typography className="!text-lg pb-4">{render(blok.content)}</Typography>
        ) : null}
        {blok?.buttons ? (
          <div className="flex flex-col md:flex-row gap-4 mt-4 items-center">
            {blok?.buttons?.map((button: CtaButtonStoryblok) => (
              // eslint-disable-next-line no-underscore-dangle
              <DynamicComponent blok={button} key={button._uid} />
            ))}
          </div>
        ) : null}
      </div>
      {blok?.image?.filename ? (
        <div className="order-1 lg:order-2 max-w-4xl">
          <Image
            src={getCmsAssetUrl(blok?.image?.filename)}
            width={imageDimensions?.width}
            height={imageDimensions?.height}
            alt={blok.image.alt}
          />
        </div>
      ) : null}
    </section>
  )
}
