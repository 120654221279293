import { BackgroundBlockStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { DynamicComponent } from '../../DynamicComponent'

interface BackgroundBlockProps {
  blok: BackgroundBlockStoryblok
}

const styles = {
  root: 'bg-gradient-to-r from-secondary/40 to-secondary/10 py-12',
  tilt: 'skew-y-6 mt-28',
  content: {
    root: 'mx-auto',
    tilt: '-skew-y-6 -mt-28',
  },
}

export const BackgroundBlock = ({ blok }: BackgroundBlockProps) => (
  <div
    className={tw({
      [styles.root]: true,
      [styles.tilt]: blok.tilt,
    })}
  >
    <div
      className={tw({
        [styles.content.root]: true,
        [styles.content.tilt]: blok.tilt,
      })}
    >
      {blok?.content?.map((singleBlok) => (
        // eslint-disable-next-line no-underscore-dangle
        <DynamicComponent blok={singleBlok} key={singleBlok._uid} />
      ))}
    </div>
  </div>
)
