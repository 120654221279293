import { useState } from 'react'
import { useMount } from 'react-use'
import { Card } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

/**
 * An extremely hacky method to creating a contents table for the Terms & Conditions page.
 * DO NOT EXTEND. And don't judge me.
 */
export const TableOfContents = () => {
  const { t } = useTranslation('common')
  const [titles, setTitles] = useState<string[]>([])
  const [contents, setContents] = useState<HTMLElement[][]>([])

  useMount(() => {
    const pageTitles = document.getElementsByTagName('h1')
    setTitles(
      Array.from(pageTitles)
        .map((title) => title.innerText)
        .filter(Boolean),
    )

    const richTextBloks = Array.from(document.getElementsByClassName('post-content-blok'))
    richTextBloks.forEach((blok) => {
      blok.childNodes.forEach((child) => {
        if (child.nodeName === 'OL') {
          const orderedList = child as HTMLOListElement
          const orderedListHeaders = Array.from(orderedList.getElementsByTagName('strong'))
          if (orderedListHeaders.length > 0) {
            setContents((prev) => [...prev, orderedListHeaders])
          }
        }
      })
    })
  })

  return (
    <Card className="max-w-2xl mx-auto mb-12">
      <h2 className="text-center">{t('utility.table_of_contents')}</h2>

      {contents.map((list, i) => (
        <div key={titles[i]}>
          <h3 className="mb-2 mt-4">{titles[i]}</h3>
          <ol className="[&_li]:text-primary columns-2">
            {list.map((content) => {
              // eslint-disable-next-line no-param-reassign
              content.style.scrollMarginTop = '100px'

              return (
                <li key={content.innerText}>
                  <button
                    className="text-white hover:text-tertiary"
                    onClick={() => content.scrollIntoView({ behavior: 'smooth' })}
                  >
                    {content.innerText}
                  </button>
                </li>
              )
            })}
          </ol>
        </div>
      ))}
    </Card>
  )
}
