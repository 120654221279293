import Image from 'next/image'
import Link from 'next/link'

import { PartnershipsHeroStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { Button, Typography } from '@electro/shared-ui-components'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

interface PartnershipsHeroProps {
  blok: PartnershipsHeroStoryblok
}

const styles = {
  root: 'bg-white md:-skew-y-6 -skew-y-3 py-12 mb-24 md:mt-16',
  realign: 'md:skew-y-6 skew-y-3 max-w-6xl mx-auto px-4 md:pt-12 pb-0 relative md:-mb-28',
  headline: '!text-base-dark max-w-lg',
  buttonLink: 'no-underline max-w-max flex-shrink-0 order-2 md:order-1 md:mt-18 mt-8',

  logos: '-mt-20 mb-4 pt-10 sm:pt-4 self-end lg:self-start max-w-2xl w-full',
  featuredImage: 'order-1 md:order-2 relative -right-4 md:-right-8',
}

export const PartnershipsHero = ({ blok }: PartnershipsHeroProps) => {
  const featureImageDimensions = blok.mainImage
    ? getStoryBlokImageDimensions(blok.mainImage.filename)
    : null
  const logoDimensions = blok.logo ? getStoryBlokImageDimensions(blok.logo.filename) : null

  return (
    <div className={styles.root}>
      <div className={styles.realign}>
        <div className="flex flex-col lg:flex-row-reverse">
          {blok.logo ? (
            <Image
              id="oem-logo"
              className={styles.logos}
              src={getCmsAssetUrl(blok.logo.filename)}
              width={logoDimensions?.width}
              height={logoDimensions?.height}
              alt={blok.logo.alt}
              quality={100}
            />
          ) : null}
          <Typography variant="h1" as="h1" className={styles.headline}>
            {blok.headline}
          </Typography>
        </div>
        <div className="flex justify-between flex-col md:flex-row">
          {blok.buttonText ? (
            <Link
              data-testid="cta-link"
              href={blok.buttonHref || '/sign-up/magic'}
              className={styles.buttonLink}
            >
              <Button>{blok.buttonText}</Button>
            </Link>
          ) : null}
          {blok.mainImage ? (
            <Image
              className={styles.featuredImage}
              src={getCmsAssetUrl(blok.mainImage.filename)}
              width={featureImageDimensions?.height}
              height={featureImageDimensions?.width}
              alt={blok.mainImage.alt}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
