import { useStatsChartData } from '@electro/consumersite/src/services/rest/useStatsChartData'
import { CONNECTORS_BY_SPEED } from '@electro/consumersite/src/constants/chartFileNames'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import electroTheme from '@electro/shared/theme/electro'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  BarElement,
  Chart,
} from 'chart.js'
import { useMemo, useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { Alert } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend)

function numberFormatter(number: number): string {
  if (number >= 1000) {
    const formattedNumber = (number / 1000).toFixed(1)
    return `${formattedNumber}k`
  }
  return `${number}`
}

const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: true,

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'NUMBER OF CHARGING CONNECTORS BY SPEED IN THE UK',
      color: electroTheme.extend.colors.secondary.DEFAULT,
      align: 'start',
      padding: {
        bottom: 30,
      },
      font: {
        size: 18,
      },
    },
    datalabels: {
      display: true,
      color: 'white',
      formatter: numberFormatter,
      anchor: 'end',
      offset: 3,
      font: {
        weight: 'bold',
        size: 16,
      },
      align: 'start',
    },
  },
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
}

export const GbrConnectorsBySpeed = () => {
  const { data, loading, error } = useStatsChartData({ fileName: CONNECTORS_BY_SPEED })
  const chartRef = useRef<Chart<'bar', string[], unknown>>(null)
  const { t } = useTranslation('common')

  const formattedBarChartData = useMemo((): ChartData<'bar', string[], string> => {
    if (!data?.result) return null

    return {
      labels: data.result.data_array.map(([speed, count]) => speed),
      datasets: [
        {
          data: data.result.data_array.map(([speed, count]) => count),
          backgroundColor: electroTheme.extend.colors.secondary.DEFAULT,
        },
      ],
    }
  }, [data])

  return (
    <div data-testid="gbr-connectors-by-speed-chart" className="relative">
      {loading && <div>{t('common_loading')}</div>}
      {error && (
        <Alert variant="error">
          <div className="font-bold">{t('common_error_title')}</div>
          <div className="font-bold">{t('common_error_subtitle')}</div>
        </Alert>
      )}

      {formattedBarChartData ? (
        <>
          <Image
            className="absolute -top-6 right-0 hidden md:block"
            src="/images/electroverse-logo-light.svg"
            alt="Electroverse Logo"
            width="150"
            height="50"
          />
          <Bar
            ref={chartRef}
            options={options}
            data={formattedBarChartData}
            plugins={[ChartDataLabels]}
          />
        </>
      ) : null}
    </div>
  )
}
