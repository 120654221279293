import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

import { FleetsInfoStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '../../helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '../../helpers/getStoryBlokImageDimensions'

interface FleetsInfoProps {
  blok: FleetsInfoStoryblok
}

const styles = {
  root: 'relative mb-24',
  background:
    'bg-gradient-to-r from-secondary/40 to-secondary/10 skew-y-6 absolute top-16 left-0 w-full h-full',
  features: tw(
    '[&_h2]:text-primary',
    '[&_ul]:pl-5 [&_p]:w-80',
    '[&_ul>li:last-child]:pb-6',
    // 1st level nested list item styles
    '[&_li]:list-item [&_li]:relative',
    '[&_li]:before:w-3 [&_li]:before:h-7 [&_li]:before:block',
    '[&_li]:before:absolute [&_li]:before:-left-5 [&_li]:before:-top-0.5',
    '[&_li]:before:bg-list-pink-zap [&_li]:before:bg-contain [&_li]:before:bg-no-repeat',
  ),
}

export const FleetsInfo = ({ blok }: FleetsInfoProps) => (
  <div className={styles.root}>
    <div className={styles.background} />
    <div className="flex flex-col-reverse lg:flex-row gap-8 max-w-sm sm:max-w-lg md:max-w-5xl mx-auto pt-40 relative">
      <div className="flex flex-col items-center gap-8 mx-auto md:max-w-3xl">
        <div className="text-center md:text-left">{render(blok.intro)}</div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className={styles.features}>{render(blok.content1)}</div>
          <div className={styles.features}>{render(blok.content2)}</div>
        </div>
      </div>

      {blok?.image?.filename ? (
        <div className="max-w-64 mx-auto">
          <Image
            src={getCmsAssetUrl(blok.image.filename)}
            alt={blok.image.alt}
            width={getStoryBlokImageDimensions(blok.image.filename).width}
            height={getStoryBlokImageDimensions(blok.image.filename).height}
          />
        </div>
      ) : null}
    </div>
  </div>
)
