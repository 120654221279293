import { LazyImage } from '@electro/shared-ui-components'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { FeatureImageStoryblok } from '@electro/consumersite/types/generated/storyblok'

interface FeatureImageProps {
  blok: FeatureImageStoryblok
}

export const FeatureImage = ({ blok }: FeatureImageProps) => (
  <div
    data-testid="featured-image"
    className="max-w-6xl h-44 sm:h-72 md:h-96 lg:h-112 overflow-hidden w-full mx-auto relative rounded-2xl bg-tertiary-shade mb-12"
  >
    <LazyImage
      priority
      fill
      className="object-cover"
      src={`${getCmsAssetUrl(blok.image.filename)}/m/1728x0`}
      alt={blok.image.alt}
    />
  </div>
)
