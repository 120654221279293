import { useCallback, useState } from 'react'
import { Modal, LazyImage } from '@electro/shared-ui-components'
import { VideoBannerStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

interface VideoBannerProps {
  blok: VideoBannerStoryblok
}

const IMG_MAX_WIDTH = 768

export const VideoBanner = ({ blok }: VideoBannerProps) => {
  const [modalOpen, setModalOPen] = useState<boolean>(false)

  const toggleModal = useCallback(() => {
    setModalOPen((oldModalOpen) => !oldModalOpen)
  }, [])

  const imageDimensions = getStoryBlokImageDimensions(blok.image.filename)

  const constrainedDimensions =
    imageDimensions && imageDimensions?.width > IMG_MAX_WIDTH
      ? {
          width: IMG_MAX_WIDTH,
          height: imageDimensions.height / (imageDimensions.width / IMG_MAX_WIDTH),
        }
      : imageDimensions

  return (
    <section
      data-testid="media-banner"
      className="max-w-3xl w-full mx-auto pt-8 pb-8 md:pt-16 md:pb-16 px-2 relative z-10"
    >
      <button onClick={toggleModal}>
        <LazyImage
          className="cursor-pointer hover:opacity-90 rounded-3xl"
          src={`${getCmsAssetUrl(blok.image.filename)}/m/1536x0`}
          alt={blok.image.alt}
          width={constrainedDimensions.width}
          height={constrainedDimensions.height}
        />
      </button>
      <Modal open={modalOpen} onClose={toggleModal} size="full">
        <Modal.CloseButton />
        <Modal.Body>
          <div className="full-width-iframe-hack">
            <iframe
              width={560}
              height={315}
              src={blok.embeddedVideoUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
}
