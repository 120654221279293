import {
  GbrConnectorsBySpeed,
  GbrConnectorsByNetwork,
  GbrConnectorsByType,
} from '@electro/consumersite/src/storyblok/bloks/Chart/components'
import { Alert } from '@electro/shared-ui-components'

/**
 * These enums come from the "Data Source" charts defined in storyblok.
 * Our typeGen setup unfortunately doesn't provide the data sources as enums
 * so we need to hard code them here.
 */
enum ChartNamesEnum {
  GBR_CONNECTOR_BY_SPEED = 'GBR number of connector types by speed',
  GBR_CPO_BY_SPEED = 'CPO distribution by speed',
  GBR_CONNECTOR_BY_TYPE = 'Number of connectors by socket type',
}

const { GBR_CONNECTOR_BY_SPEED, GBR_CPO_BY_SPEED, GBR_CONNECTOR_BY_TYPE } = ChartNamesEnum

type ChartNames = `${ChartNamesEnum}`

interface ChartProps {
  blok: {
    chartType: ChartNames
  }
}

export const Chart = ({ blok }: ChartProps) => {
  const mapChartNameToComponent = (chart: ChartNames) => {
    const chartMap = {
      [GBR_CONNECTOR_BY_SPEED]: <GbrConnectorsBySpeed />,
      [GBR_CPO_BY_SPEED]: <GbrConnectorsByNetwork />,
      [GBR_CONNECTOR_BY_TYPE]: <GbrConnectorsByType />,
    }
    return chartMap[chart] ? (
      <div className="px-4">
        <div className="py-2 px-3 sm:p-8 bg-white rounded-3xl shadow-md mb-16 mt-8 max-w-6xl mx-auto border-2 border-secondary">
          {chartMap[chart]}
        </div>
      </div>
    ) : (
      <Alert variant="warn" className="m-2">
        Warning! <strong>&quot;{chart}&quot;</strong> chart not found.
      </Alert>
    )
  }

  return mapChartNameToComponent(blok.chartType)
}
