import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import { OctoEcosystemStoryblok } from '@electro/consumersite/types/generated/storyblok'
import Image from 'next/image'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

interface OctoEcosystemProps {
  blok: OctoEcosystemStoryblok
}

const styles = {
  root: 'text-center max-w-6xl mx-auto px-8 pb-0 pt-32 relative',
  content: 'max-w-xl mx-auto pb-18 relative z-10',
  image: 'mx-auto w-fit mb-12 sm:mb-8 relative z-10 mt-8 self-end max-h-40',
}

export const OctoEcosystem = ({ blok }: OctoEcosystemProps) => {
  const imageSections = [
    {
      image: blok.image1,
      content: blok.text1,
    },
    {
      image: blok.image2,
      content: blok.text2,
    },
    {
      image: blok.image3,
      content: blok.text3,
    },
  ]
  return (
    <div className={styles.root}>
      <div className="bg-skew h-5/6 sm:h-3/5 absolute top-28 sm:top-20 -left-1/2 w-[200%] bg-gradient-to-r sm:from-secondary/30 sm:to-secondary/10 from-secondary/10 to-secondary/0 pointer-events-none skew-y-6" />
      <div className={styles.content}>{render(blok.body)}</div>
      <div className="md:grid md:grid-cols-3 md:gap-6 px-8 relative z-10">
        {imageSections.map((section, idx) => {
          const { width, height } = getStoryBlokImageDimensions(section.image.filename)
          return (
            <div
              key={section.image.id}
              data-test-id={`eco-${idx}`}
              className="md:grid md:grid-rows-2 md:grid-cols-1 pt-8 first:pt-0 md:mt-0 md:first:pt-8"
            >
              <Image
                className={styles.image}
                src={getCmsAssetUrl(section.image.filename)}
                alt={section.image.alt}
                width={width}
                height={height}
              />
              <div className="max-w-sm mx-auto">{render(section.content)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
