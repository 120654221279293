/* eslint-disable react/jsx-no-useless-fragment */
import { Locales } from '@electro/shared/types/locales'
import Link, { LinkProps } from 'next/link'
import {
  RenderOptions,
  StoryblokRichtext,
  render as sbRender,
  MARK_TEXT_STYLE,
  MARK_BOLD,
  MARK_LINK,
} from 'storyblok-rich-text-react-renderer'

type Document = StoryblokRichtext | unknown

const locales = Object.values(Locales)

const removeLocale = (href: string) => {
  const locale = href.split('/')[1]
  return locales.includes(locale as Locales)
    ? href.split('/').slice(2).join('/')
    : href.split('/').slice(1).join('/')
}

const getLocale = (href: string) => {
  const locale = href.split('/')[1]
  if (locale === Locales.EN) {
    return ''
  }
  return locales.includes(locale as Locales) ? `${locale}/` : ''
}

export const sanitisedHrefProps = (href: string) => {
  /**
   * Sometimes nullish hrefs are passed to the Link component by the CMS causing
   * the Link component from throwing an error in build.
   * We can catch this here and provide a default empty string to prevent the error.
   */
  if (!href) {
    return { href: '' }
  }

  const isInternalLink = href.startsWith('/')

  if (!isInternalLink) {
    return { href: href || '' }
  }

  /**
   * Internal links should always be lowercase and have a locale prefix.
   */
  const sanitisedHref = `/${getLocale(href)}${removeLocale(href).toLowerCase()}`

  /**
   * Community templates are nested under the /community route.
   * We need to ensure that the hrefPrefix is set correctly for these links or the app will
   * render the wrong templates.
   */
  const hrefPrefix = sanitisedHref.includes('/community') ? '/community' : ''

  return {
    as: sanitisedHref,
    href: `${hrefPrefix}/[...slug]`,
  }
}

/**
 * This function acts as a proxy to the storyblok-rich-text-react-renderer render function.
 * It provides custom mark resolvers for the text style, bold, and link marks.
 *
 * This is to:
 * - Prevent CMS users from attaching arbitrary inline styles to text
 * - Ensure that bold text is rendered with a strong tag
 * - Ensure that links are rendered with the Next.js Link component over an <a/> tag
 */
export function render(document: Document, options?: RenderOptions) {
  const customOptions: RenderOptions = {
    ...options,
    markResolvers: {
      [MARK_TEXT_STYLE]: (children) => <>{children}</>,
      [MARK_BOLD]: (children) => <strong className="font-medium">{children}</strong>,
      [MARK_LINK]: (children, { href, ...restProps }) => (
        <Link {...(restProps as LinkProps)} {...sanitisedHrefProps(href)}>
          {children}
        </Link>
      ),
    },
  }

  return sbRender(document, customOptions)
}
