import { InfoBoxStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { LazyImage } from '@electro/shared-ui-components'

type InfoBoxProps = {
  blok: InfoBoxStoryblok
}

const styles = {
  root: tw('px-4 py-6 max-w-2xl mx-auto mb-8 mt-12  flex bg-info-light', 'rounded-2xl '),
  text: 'text-info-dark ml-4 flex-shrink m-0 p-0',
}

export const InfoBox = ({ blok }: InfoBoxProps) => (
  <div className={styles.root}>
    <div className="flex-shrink-0">
      <LazyImage src="/images/info-icon-bg.svg" alt="information icon" width={49} height={52} />
    </div>
    <p className={styles.text}>{blok.text}</p>
  </div>
)
