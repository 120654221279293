import { FeaturesStoryblok } from '@electro/consumersite/types/generated/storyblok'
import Image from 'next/image'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

interface FeaturesProps {
  blok: FeaturesStoryblok
}

const styles = {
  root: 'skew-y-6 bg-secondary/20 overflow-hidden relative mb-32',
  content: '-skew-y-6 mx-auto max-w-6xl w-full px-8 sm:pb-24 pb-16 pt-8',
  copy: 'w-full sm:w-[calc(33.333%-theme(spacing.8))] [&>h2]:text-primary',
  image:
    'hidden sm:block object-cover object-right w-full absolute right-16 sm:-right-1/3 top-0 lg:-top-3/4 pointer-events-none select-none',
}

export const Features = ({ blok }: FeaturesProps) => (
  <section data-testid="features" className={styles.root}>
    <div className={styles.content}>
      <div className={styles.copy}>{render(blok.body)}</div>
    </div>
    <Image
      fill={false}
      className={styles.image}
      src="/images/app-screens-lg.png"
      width={3249}
      height={2650}
      alt="Screenshots of the electroverse app"
    />
  </section>
)
