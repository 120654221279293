/**
 * Takes any string and retturns a url friendly slug.
 * @param value
 */

type Slugify = string

const slugify = (str: string): Slugify => {
  if (!str) return ''
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export default slugify
