import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

import { RichTextStoryblok } from '@electro/consumersite/types/generated/storyblok'
import clsx from 'clsx'

interface PostContentProps {
  blok: RichTextStoryblok
}

const styles = {
  root: 'mx-auto mb-8 post-content-blok postContent postLinks override-list-style px-4 [&_ul]:list-disc [&_li]:text-primary',
  narrow: 'max-w-2xl',
  fullWidth: 'max-w-6xl',
}

export const PostContent = ({ blok }: PostContentProps) => (
  <div
    className={clsx({
      [styles.root]: true,
      [styles.narrow]: !blok.fullWidth,
      [styles.fullWidth]: blok.fullWidth,
    })}
    data-testid="rich-text-blok"
  >
    {render(blok.body)}
  </div>
)
