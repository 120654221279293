/* eslint-disable no-underscore-dangle */
import { RequestFleetsDemoEmail } from '@electro/consumersite/src/components'
import { RequestFleetsDemoStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { Button, Modal, Typography } from '@electro/shared-ui-components'

import { render } from 'storyblok-rich-text-react-renderer'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'
import Image from 'next/image'

import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { DynamicComponent } from '@electro/consumersite/src/storyblok/DynamicComponent'

export const RequestFleetsDemo = ({ blok }: { blok: RequestFleetsDemoStoryblok }) => {
  const { t } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [success, setSuccess] = useState(false)

  const toggleModal = () => setModalOpen((oldModalOpen) => !oldModalOpen)

  const handleSuccess = ({ email }) => {
    setSuccess(true)
    setEmailAddress(email)
  }

  const dimensions = getStoryBlokImageDimensions(blok?.image.filename)

  return (
    <section
      data-testid="RequestFleetsDemo"
      className="grid grid-cols-1 lg:grid-cols-12 lg:gap-12 max-w-4xl lg:max-w-7xl mx-auto py-8 lg:pt-16 lg:pb-8 2xl:px-0 px-8 text-center lg:text-left"
    >
      {blok.headline ? (
        <div className="col-span-1 lg:col-span-12 text-center [&_h2]:md:text-5xl [&_h2]:text-3xl [&_b]:font-bold [&_b]:text-primary mb-10  lg:-mt-12">
          {render(blok.headline)}
        </div>
      ) : null}
      {blok.image.filename ? (
        <div className="flex justify-center col-span-7 mb-4 lg:mb-0 mt-4 lg:mt-0">
          <Image
            src={getCmsAssetUrl(blok.image.filename)}
            alt={blok.image.alt}
            width={dimensions.width * 2}
            height={dimensions.height * 2}
          />
        </div>
      ) : null}
      {blok?.content.length > 0 ? (
        <div data-testid="content" className="col-span-5 flex flex-col justify-center">
          {blok?.content?.map((singleBlok) => (
            <DynamicComponent blok={singleBlok} key={singleBlok._uid} />
          ))}
          <Button className="mt-4 mx-auto lg:mx-0" onClick={toggleModal}>
            {t('utility.get_access')}
          </Button>
        </div>
      ) : null}

      <Modal size="md" open={modalOpen} onClose={toggleModal}>
        <Modal.CloseButton />
        {success ? (
          <Modal.Header>
            <div className="text-center">{t('fleets.demo.modal.success_header')}</div>
          </Modal.Header>
        ) : null}
        <Modal.Body className="px-6 overflow-visible">
          <div className="flex flex-col items-center">
            {!success ? (
              <>
                <Image
                  className="max-w-48 mx-auto -mt-8 md:-mt-12 mb-8"
                  src="/images/electroverse-logo-dark-business.svg"
                  alt="Email campaign"
                  width={300}
                  height={300}
                />
                <RequestFleetsDemoEmail onSuccess={handleSuccess} />
              </>
            ) : null}
            {success ? (
              <>
                <Image
                  src="/images/inbox-icon.png"
                  alt="illustration a email inbox icon with a notification."
                  width={160}
                  height={160}
                  className="m-auto mt-8 mb-8"
                />
                <Typography as="p" variant="body" className="text-center mb-0">
                  {t('fleets.demo.modal.message')} {emailAddress}
                </Typography>
              </>
            ) : null}
          </div>
        </Modal.Body>
        {success ? (
          <Modal.Actions stacked>
            <Button fullWidth onClick={toggleModal} className="mt-4 mb-6">
              {t('utility.button.done')}
            </Button>
          </Modal.Actions>
        ) : null}
      </Modal>
    </section>
  )
}
