import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
/* eslint-disable no-underscore-dangle */
import { OperatorStoryblok } from '@electro/consumersite/types/generated/storyblok'
import Image from 'next/image'

interface OperatorCellProps {
  blok: OperatorStoryblok
}

export const OperatorCell = ({ blok }: OperatorCellProps) => (
  <a
    className="no-underline text-base-dark font-medium flex flex-col"
    href={blok.websiteUrl}
    target="_blank"
    rel="noreferrer"
  >
    <div className="flex flex-grow justify-center items-center px-2">
      <span className="w-full h-32 relative">
        {blok?.logo?.filename && (
          <Image
            className="object-contain"
            fill
            src={getCmsAssetUrl(blok.logo.filename)}
            alt={blok.logo.alt}
          />
        )}
      </span>
    </div>
    <p className="text-center mt-2">{blok.name}</p>
  </a>
)
