import { HomepageBorderedImagesTopStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import { Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { BorderedImage } from './components/BorderedImage'
import { AppStoreButtons } from './components/AppStoreButtons'

interface HomepageBorderedImagesTopProps {
  blok: HomepageBorderedImagesTopStoryblok
}

// Style changes in this file will likely need to be reflected in BorderedImagesBottom.tsx
const styles = {
  root: 'flex flex-col-reverse md:flex-row gap-4 lg:gap-x-10 my-32',
  right:
    'flex flex-col gap-8 pr-8 lg:pr-0 pl-8 md:pl-0 basis-6/12 lg:basis-5/12 justify-around -mt-20',
  text: 'flex flex-col gap-y-1 w-full text-end pl-14',
}

export const BorderedImagesTop = ({ blok }: HomepageBorderedImagesTopProps) => {
  const { t } = useTranslation('common')

  return (
    <section className={styles.root}>
      <BorderedImage
        className="items-end pr-8 md:p-0 basis-6/12 lg:basis-5/12"
        borderClassName="p-6 -ml-4 pl-10 lg:p-14 lg:-ml-6 lg:pl-20"
        colour="Pink"
        caption=".iOS & Android"
        image={{
          filename: blok.iosAndroidImage.filename,
          alt: blok.iosAndroidImage.alt,
        }}
        modalContent={{ header: blok.iOSAndroidModalHeader, body: blok.iOSAndroidModalBody }}
      />

      <div className={styles.right}>
        <div className={styles.text}>
          <Typography variant="h2" as="h2" className="text-primary mb-3">
            {blok.title}
          </Typography>
          <Typography variant="h3" as="h3" className="text-base lg:pl-6">
            {render(blok.textarea)}
          </Typography>
          <div className="flex justify-end">
            <div className="flex flex-col md:flex-row gap-x-6 gap-y-3 items-end">
              <AppStoreButtons
                ios={{ subText: blok.AppleSubText, titleText: blok.AppleTitleText }}
                android={{ subText: blok.GoogleSubText, titleText: blok.GoogleTitleText }}
              />
            </div>
          </div>
        </div>

        <BorderedImage
          frosted
          className="items-start w-4/12 lg:w-4/12 -mt-20"
          borderClassName="p-4 xl:p-6"
          colour="Blue"
          caption={`.${t('common.charging_methods.rfid')}`}
          image={{
            filename: blok.rfidImage.filename,
            alt: blok.rfidImage.alt,
          }}
          modalContent={{ header: blok.rfidModalHeader, body: blok.rfidModalBody }}
          data-testid="rfid-bordered-image"
        />
      </div>
    </section>
  )
}
