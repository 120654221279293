import { AnimatedImageStoryblok } from '@electro/consumersite/types/generated/storyblok'
import dynamic from 'next/dynamic'

import { useEffect, useState } from 'react'

interface AnimatedStoryProps {
  blok: AnimatedImageStoryblok
  width: string | number | null
  height: string | number | null
}

export const AnimatedImage = ({ blok }: AnimatedStoryProps) => {
  const imageWidth = `${blok.width}px` || 'auto'
  const imageHeight = `${blok.height}px` || 'auto'

  const [Lottie, setLottie] = useState(undefined)

  // We need to dynamically import Lottie here avoid SSR issues
  useEffect(() => {
    setLottie(dynamic(() => import('lottie-react'), { ssr: false }))
  }, [])

  return blok.json ? (
    <div className="max-w-xs mx-auto">
      <Lottie
        style={{ height: imageHeight, width: imageWidth }}
        animationData={JSON.parse(blok.json)}
        test-id="animation-image"
      />
    </div>
  ) : null
}
