import { BigTextStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { BigTextStatBlock } from './BigTextStatBlock'
import { SubtleStatBlock } from './SubtleStatBlock'

interface BigTextProps {
  blok: BigTextStoryblok
}

const componentMap = {
  BigTextStatBlock,
  SubtleStatBlock,
}

const mapComponentToVariant = ({ blok }: BigTextProps) => {
  const Component = componentMap[blok.variant] || BigTextStatBlock
  return <Component blok={blok} />
}

export const BigText = ({ blok }: BigTextProps) => mapComponentToVariant({ blok })
