import { tw } from '@electro/shared/utils/tailwind-merge'
import { useState } from 'react'
import MuxPlayer from '@mux/mux-player-react'
import { VideoPlayerStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { Typography } from '@electro/shared-ui-components'
import { extend } from '@electro/shared/theme/electro'
import styles from './VideoPlayer.module.css'

interface VideoPlayerProps {
  blok: VideoPlayerStoryblok
}

export const VideoPlayer = ({ blok }: VideoPlayerProps) => {
  const [hideControlBar, setHideControlBar] = useState<boolean>(true)

  return (
    <section
      className={tw('flex flex-col justify-center mx-auto text-center', {
        [styles.defaultStyles]: true,
        [blok.videoWidth]: blok.videoWidth,
        'aspect-video': blok.orientationLandscape,
        'aspect-[9/16]': !blok.orientationLandscape,
        [styles.hideControlBar]: hideControlBar,
        'p-8': !blok.disableMargins,
      })}
    >
      <style>
        {/* These selectors target the 'exportparts' attribute */}
        {/* The `margin-top: -6px;` fixes the harsh cutoff on the rounded border */}
        {`mux-player::part(video) {
            margin-top: -6px;
            border-radius: calc(${blok.styledRoundedCorners} + 2px);
          }
          mux-player::part(poster img) {
            margin-top: -6px;
            border-radius: ${blok.styledRoundedCorners};
            object-fit: cover;
          }`}
      </style>

      <MuxPlayer
        streamType="on-demand"
        playbackId={blok.muxPlaybackID}
        poster={blok.bannerImage?.alt ? `${getCmsAssetUrl(blok.bannerImage?.filename)}/m/` : ''}
        onPlay={() => setHideControlBar(false)}
        secondaryColor={extend.colors.base.DEFAULT}
        className="z-10"
      />

      <div className="z-10 mt-1">
        {blok.caption && <Typography variant="p">{blok.caption}</Typography>}
        {blok.youtubeLink?.url && (
          <a href={blok.youtubeLink.url} target="_blank" rel="noreferrer">
            <Typography variant="small">[YouTube]</Typography>
          </a>
        )}
      </div>
    </section>
  )
}
