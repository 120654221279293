import { LazyImage } from '@electro/shared-ui-components'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import { AppScreensV2Storyblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '../../helpers/getStoryBlokImageDimensions'
import { AppStoreButtons } from '../HomepageSections/components/AppStoreButtons'

interface AppScreensV2Props {
  blok: AppScreensV2Storyblok
}

export const AppScreensV2 = ({ blok }: AppScreensV2Props) => (
  <section
    data-testid="app-screens-download-v2"
    className="max-w-md md:max-w-6xl w-full mx-auto px-4 pt-8 pb-12 md:pt-2 md:pb-12 gap-4 md:gap-20 flex flex-col md:flex-row  items-center"
  >
    <div className="pb-8 md:pb-0 relative z-10 flex-shrink-0 max-w-md md:order-2 order-1">
      {blok.image.filename ? (
        <LazyImage
          src={getCmsAssetUrl(blok.image.filename)}
          alt={blok.image.alt}
          width={getStoryBlokImageDimensions(blok.image.filename).width}
          height={getStoryBlokImageDimensions(blok.image.filename).height}
        />
      ) : null}
    </div>
    <div className="md:col-span-2 flex flex-col items-start justify-start md:order-1 order-2">
      <div>{render(blok.body)}</div>
      <div className="flex flex-row gap-x-6 pt-4">
        <AppStoreButtons
          ios={{ subText: blok.AppleSubText, titleText: blok.AppleTitleText }}
          android={{ subText: blok.GoogleSubText, titleText: blok.GoogleTitleText }}
        />
      </div>
    </div>
  </section>
)
