/* eslint-disable no-underscore-dangle */
import React from 'react'
import { PostVideoStoryblok } from '@electro/consumersite/types/generated/storyblok'

type Props = {
  blok: PostVideoStoryblok
}

const styles = {
  root: 'max-w-2xl mx-auto pb-8 pt-8',
  video: 'm-auto max-w-full',
  caption: 'w-full block text-left text-base-dark/50 text-sm mt-3',
}

export const PostVideo = ({ blok }: Props) => (
  <div className={styles.root}>
    <video width={blok?.width || null} controls className={styles.video}>
      <source src={blok.video.filename} />
      <track kind="captions" />
    </video>
    {blok?.caption && (
      <div id={`${blok._uid}-caption`} className={styles.caption}>
        {blok.caption}
      </div>
    )}
  </div>
)
