import useSWR from 'swr'
import { DatabricksSqlStatementJsonResponse } from '@electro/consumersite/types/databricks'
import * as Sentry from '@sentry/nextjs'

import { fetcher } from '@electro/consumersite/src/services/rest/fetcher'
import { useEffect } from 'react'

interface UseStatsChartData {
  data: DatabricksSqlStatementJsonResponse
  loading: boolean
  error: boolean
}

interface UseStatsChartDataArgs {
  fileName: string
}

export function useStatsChartData({ fileName }: UseStatsChartDataArgs): UseStatsChartData {
  const { data, error, isLoading: loading } = useSWR(`/api/stats/data/${fileName}.json`, fetcher)

  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
    }
  }, [error])

  return {
    data,
    loading,
    error,
  }
}
