/* eslint-disable no-underscore-dangle */
import { TableStoryblok } from '@electro/consumersite/types/generated/storyblok'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableHead,
} from '@electro/shared-ui-components'

interface TableProps {
  blok: TableStoryblok
}

const TableStoryblokComponent = ({ blok }: TableProps) =>
  blok.table ? (
    <Table variant={blok.variant}>
      {blok.table.thead.length > 0 ? (
        <TableHead>
          <TableRow>
            {blok.table.thead.map((head) => (
              <TableHeader key={head._uid}>{head.value}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
      ) : null}
      {blok.table.tbody.length > 0 ? (
        <TableBody>
          {blok.table.tbody.map((row) => (
            <TableRow key={row._uid}>
              {row.body.map((cell) => (
                <TableCell key={cell._uid}>{cell.value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      ) : null}
    </Table>
  ) : null

export { TableStoryblokComponent as Table }
