/* eslint-disable no-underscore-dangle */
import { DynamicComponent } from '@electro/consumersite/src/storyblok'
import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

const styles = {
  root: 'py-8 ml-8 sm:ml-0 relative grid grid-cols-1 sm:grid-cols-2 w-full gap-12 justify-self-center items-center pr-2',
  pinkLine: {
    lineDefinition: "before:content-[''] before:w-1 before:h-full",
    position: 'before:absolute before:bg-primary before:-left-6 sm:before:left-[calc(43%-1px)]',
  },
  purpleCircle: {
    shapeDefinition:
      "after:absolute after:content-[''] after:w-5 after:h-5 after:bg-secondary after:border-4 after:rounded-full after:border-white",
    position:
      'after:-left-[22px] sm:after:left-[calc(43%+1px)] after:top-1/2 after:-translate-x-1/2 sm:after:-translate-x-1/2 after:-translate-y-[120px] sm:after:-translate-y-1/2',
  },
}

export const TimelineBlock = ({ blok }) => {
  const dimensions = getStoryBlokImageDimensions(blok?.image?.filename)
  return (
    <div
      data-testid="TimelineBlock"
      className={tw(
        styles.root,
        styles.pinkLine.lineDefinition,
        styles.pinkLine.position,
        styles.purpleCircle.shapeDefinition,
        styles.purpleCircle.position,
      )}
    >
      {blok.image?.filename ? (
        <Image
          className="max-w-56 w-3/4 sm:max-w-xs sm:mx-auto"
          src={getCmsAssetUrl(blok.image.filename)}
          alt={blok.image.alt}
          width={dimensions.width}
          height={dimensions.height}
        />
      ) : null}
      <div className="pr-6 sm:-ml-10">
        {blok.content.map((innerBlok) => (
          <DynamicComponent key={innerBlok._uid} blok={innerBlok} />
        ))}
      </div>
    </div>
  )
}
