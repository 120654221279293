import { RichtextStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { Modal } from '@electro/shared-ui-components'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

interface ImageInfoModalProps {
  modalVisible: boolean
  setModalVisible: (value?: boolean) => void
  content: {
    header: string
    body: RichtextStoryblok
  }
}

export const ImageInfoModal = ({ modalVisible, setModalVisible, content }: ImageInfoModalProps) => (
  <Modal size="md" open={modalVisible} onClose={() => setModalVisible(false)}>
    <Modal.CloseButton />

    <Modal.Header>{content.header}</Modal.Header>
    <Modal.Body className="override-list-style">{render(content.body)}</Modal.Body>
  </Modal>
)
