import { CategorySubnavigation, Footer, TopNavigation } from '@electro/consumersite/src/components'
import { FormattedPost } from '@electro/consumersite/src/storyblok/helpers/formatPostsForGallery'
import { HeroCommunityPost, PostGallery } from '@electro/consumersite/src/storyblok/bloks'
import { DataSourceEntry } from '@electro/consumersite/types/storyblok'

interface CategoryLayoutProps {
  featuredPost?: FormattedPost
  posts?: FormattedPost[]
  categories?: DataSourceEntry[]
  currentCategory?: DataSourceEntry
}

const styles = {
  root: 'relative bg-white overflow-hidden w-full',
  content: 'flex-grow relative light:px-4 lg:px-0 light:max-w-6xl m-auto pb-16',
}

export const CategoryLayout = ({
  posts,
  currentCategory,
  categories,
  featuredPost,
}: CategoryLayoutProps) => (
  <div className={styles.root}>
    <div>
      <TopNavigation />
      <main className={styles.content} data-testid="page-template-community-categories">
        {featuredPost ? (
          <HeroCommunityPost post={featuredPost} currentCategorySlug={featuredPost.categories[0]} />
        ) : null}
        <div className="px-2">
          <CategorySubnavigation categories={categories} />
        </div>
        {posts.length > 0 ? (
          <div className="px-2">
            <PostGallery {...{ posts }} currentCategorySlug={currentCategory?.value} />
          </div>
        ) : null}
      </main>
      <Footer />
    </div>
  </div>
)
