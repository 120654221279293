/**
 * For reference:
 * https://www.storyblok.com/faq/image-dimensions-assets-js
 *
 * This feels fragile but it's the recommended method from StoryBlok.
 *
 * @param url storyblok url
 * @returns width and height dimensions generated from the url
 */
import { STORYBLOK_SPACE_URL } from '@electro/shared/constants'

interface GetStoryBlokImageDimensions {
  width: number
  height: number
}

export function getStoryBlokImageDimensions(url: string): GetStoryBlokImageDimensions {
  if (!url) return null
  if (url && !url?.includes(STORYBLOK_SPACE_URL)) throw new Error('invalid Storyblok image URL')
  const [width, height] = url.split('/')[5].split('x')

  // some older svg images may not have dimensions, this will catch those cases.
  if (Number.isNaN(width) || Number.isNaN(height) || !width || !height) return null

  return {
    width: parseInt(width, 10),
    height: parseInt(height, 10),
  }
}
