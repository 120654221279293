import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  BarElement,
  Chart,
} from 'chart.js'
import { useMemo, useRef } from 'react'
import { Bar } from 'react-chartjs-2'

import electroTheme from '@electro/shared/theme/electro'
import { useStatsChartData } from '@electro/consumersite/src/services/rest'
import useTranslation from 'next-translate/useTranslation'
import { CONNECTORS_BY_NETWORK } from '@electro/consumersite/src/constants'
import { Alert } from '@electro/shared-ui-components'
import Image from 'next/image'

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend)

const options: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'NUMBER OF CONNECTORS PER OPERATOR',
      color: electroTheme.extend.colors.secondary.DEFAULT,
      align: 'start',
      padding: {
        bottom: 30,
      },
      font: {
        size: 18,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

export const GbrConnectorsByNetwork = () => {
  const { data, loading, error } = useStatsChartData({ fileName: CONNECTORS_BY_NETWORK })
  const chartRef = useRef<Chart<'bar', string[], unknown>>(null)
  const { t } = useTranslation('common')

  const formattedBarChartData = useMemo((): ChartData<'bar', string[], string> => {
    if (!data?.result) return null

    const dataSortedByCount = data.result.data_array.sort(
      ([countA, operatorA], [countB, operatorB]) => parseInt(countB, 10) - parseInt(countA, 10),
    )

    return {
      labels: dataSortedByCount.map(([count, operator]) => operator),
      datasets: [
        {
          data: dataSortedByCount.map(([count, operator]) => count),
          backgroundColor: electroTheme.extend.colors.secondary.DEFAULT,
        },
      ],
    }
  }, [data])

  return (
    <div data-testid="gbr-connectors-by-network" className="relative">
      {loading && <div>{t('common_loading')}</div>}
      {error && (
        <Alert variant="error">
          <div className="font-bold">{t('common_error_title')}</div>
          <div className="font-bold">{t('common_error_subtitle')}</div>
        </Alert>
      )}
      {formattedBarChartData ? (
        <>
          <Image
            className="absolute -top-6 right-0 hidden md:block"
            src="/images/electroverse-logo-light.svg"
            alt="Electroverse Logo"
            width="150"
            height="50"
          />
          <Bar ref={chartRef} options={options} data={formattedBarChartData} />
        </>
      ) : null}
    </div>
  )
}
