/* eslint-disable no-underscore-dangle */
import { Card as SharedUiCard } from '@electro/shared-ui-components'
import { CardStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { DynamicComponent } from '@electro/consumersite/src/storyblok/DynamicComponent'

interface CardProps {
  blok: CardStoryblok
}

/**
 * This is a proxy to the shared-ui-components Card component.
 * It needs the flex flex-col classnames to allow us to pull
 * elements to the bottom of the card with mt-auto.
 */
export const Card = ({ blok }: CardProps) => (
  <SharedUiCard data-testid="sb-card" className="relative flex flex-col">
    {blok?.content?.map((innerBlok) => (
      <DynamicComponent key={innerBlok._uid} blok={innerBlok} />
    ))}
  </SharedUiCard>
)
