import Link from 'next/link'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { LazyImage } from '@electro/shared-ui-components'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { PostDate } from '@electro/consumersite/src/components'
import { FormattedPost } from '@electro/consumersite/src/storyblok/helpers/formatPostsForGallery'
import Image from 'next/image'

interface Props {
  post: FormattedPost
  currentCategorySlug: string
  showConstantine?: boolean
}

const styles = {
  root: 'group no-underline',
  link: {
    root: 'no-underline group focus:ring-2 focus:ring-offset-8 block rounded-xl focus:ring-tertiary/20 focus:ring-offset-transparent',
    imageWrapper: tw(
      'max-w-6xl h-52 relative overflow-hidden w-full mx-auto rounded-2xl',
      'bg-tertiary-shade mb-4 group-hover:shadow-xl',
      'group-hover:scale-[101%] transition-all', // one off arbitrary value for a more subtle transition
    ),
    image: 'object-cover object-center w-full h-full',
    title: 'mb-1 text-base-dark ',
  },
  constantine: 'absolute top-1/2 -right-[67px] pointer-events-none z-10',
}

export const PostGalleryLink = ({ post, currentCategorySlug, showConstantine }: Props) => (
  <article key={post.uuid} className={styles.root} data-testid="post-link">
    <Link
      href="/community/[category]/[post]"
      as={`/community/${currentCategorySlug}/${post.slug}`}
      className={styles.link.root}
    >
      <div className="relative">
        {/* This image will appear broken inside the storyblok iframe because next image will load it with http: instead of https: */}
        {showConstantine ? (
          <Image
            className={styles.constantine}
            src="/images/peeping-consty.png"
            alt=""
            width={87}
            height={93}
          />
        ) : null}
        <div className={styles.link.imageWrapper}>
          <LazyImage
            fill
            className={styles.link.image}
            src={`${getCmsAssetUrl(post.featuredImage?.filename)}/m/1152x0`}
            alt={post.featuredImage?.alt || ''}
          />
        </div>
      </div>
      <h3 className={styles.link.title}>{post?.pageTitle}</h3>
      <PostDate date={post?.firstPublishedAt} />
    </Link>
  </article>
)
