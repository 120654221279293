import { Details } from '@electro/shared-ui-components'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'
import { FaqsStoryblok, FaqStoryblok } from '@electro/consumersite/types/generated/storyblok'
import slugify from '@electro/shared/utils/slugify'
import { tw } from '@electro/shared/utils/tailwind-merge'
import Link from 'next/link'

interface FaqsProps {
  blok: FaqsStoryblok
}

export const Faqs = ({ blok }: FaqsProps) => {
  const sectionAnchorSlug = slugify(blok.title)

  return (
    <>
      <div data-testid={sectionAnchorSlug} id={sectionAnchorSlug} className="spacer pt-24 -mt-24" />
      <section className={tw('max-w-6xl w-full mx-auto relative mb-12 px-2 faqs-blok')}>
        {!!blok.title && (
          <h2>
            <Link className="no-underline font-medium" href={`#${sectionAnchorSlug}`}>
              {blok.title}
            </Link>
          </h2>
        )}
        <Details>
          {blok.faqs.map((faq: FaqStoryblok) => (
            // eslint-disable-next-line no-underscore-dangle
            <DynamicComponent blok={faq} key={faq._uid} />
          ))}
        </Details>
      </section>
    </>
  )
}
