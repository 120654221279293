import Link from 'next/link'
import { Button, Head, LazyImage } from '@electro/shared-ui-components'
import { PostDate } from '@electro/consumersite/src/components'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { FormattedPost } from '@electro/consumersite/src/storyblok/helpers/formatPostsForGallery'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'

interface HeroCommunityPostProps {
  post: FormattedPost
  currentCategorySlug: string
}

export const HeroCommunityPost = ({ post, currentCategorySlug }: HeroCommunityPostProps) => {
  const { t } = useTranslation('common')
  const activeHolidaySeason = getActiveHolidaySeason()

  return (
    <>
      <Head
        title={`Electroverse | ${t('nav.menu.item.community')}`}
        image={`${getCmsAssetUrl(post?.featuredImage?.filename)}/m/1200x0`}
        showAppBanner
      />
      <div
        className="flex justify-center bg-gradient-mesh rounded-4xl sm:rounded-none px-8 pb-0 pt-32"
        data-testid="featured-post-listing"
      >
        <div className="flex flex-col-reverse md:flex-row max-w-6xl gap-x-20">
          <section className="flex basis-1/2 flex-col grow sm:pt-2  md:pt-10">
            <PostDate date={post?.firstPublishedAt} className="mb-4 text-white" />
            <h1 className="text-3xl">{post?.pageTitle}</h1>
            <p>{post?.teaserText}</p>
            <Link
              href="/community/[category]/[post]"
              as={`/community/${currentCategorySlug}/${post.slug}`}
              className="no-underline sm:max-w-max"
            >
              <Button data-testid="communityHeroPost" size="lg" className="w-full sm:w-auto mb-4">
                {t('blog.action.read_more')}
              </Button>
            </Link>
          </section>
          <div className="flex md:basis-1/2 light:max-w-6xl h-56 sm:h-72 md:h-96 light:lg:h-120 w-full mx-auto sm:mb-12 mb-6 relative">
            <div className="relative w-full overflow-hidden rounded-2xl">
              <LazyImage
                fill
                className="object-cover object-center w-full h-full"
                src={`${getCmsAssetUrl(post?.featuredImage?.filename)}/m/2000x0`}
                alt={post.featuredImage?.alt || ''}
              />
            </div>
            {activeHolidaySeason ? (
              <Image
                src={getSeasonalImagePath('constantine-2', activeHolidaySeason)}
                width={174}
                height={107}
                alt="Seasonal constantine on the holidays!"
                className="absolute -top-20 -right-18 rotate-6"
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
