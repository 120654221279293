/**
 *
 * This function fixes an injection vulnerability with cross origin when an asset url is storybloks generic url, this will
 * replace the generic storyblok url with our custom asset url.
 *
 * @param src - Your assset url.
 * @returns url with correct asset domain.
 */

import { EJ_STORY_BLOK_ASSET_DOMAIN, STORYBLOK_SPACE_URL } from '@electro/shared/constants'

export const getCmsAssetUrl = (src: string | null | undefined) => {
  if (src) {
    return src.replace(STORYBLOK_SPACE_URL, EJ_STORY_BLOK_ASSET_DOMAIN)
  }

  return EJ_STORY_BLOK_ASSET_DOMAIN
}
