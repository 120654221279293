/* eslint-disable no-underscore-dangle */
import { tw } from '@electro/shared/utils/tailwind-merge'

import { PricingStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { Card, Typography } from '@electro/shared-ui-components'
import { DynamicComponent } from '../../DynamicComponent'

interface PricingProps {
  blok: PricingStoryblok
}

const styles = {
  root: 'relative',
  background:
    'bg-gradient-to-r from-secondary/40 to-secondary/10 skew-y-3 absolute top-0 left-0 w-full h-4/5',
  card: tw(
    'max-w-sm mx-auto mb-12 relative',
    'md:mb-24',
    '[&_img]:-mt-12 [&_img]:-mb-4',
    '[&_ul]:pl-6',
    '[&_ul>li:last-child]:pb-6',
    // 1st level nested list item styles
    '[&_li]:list-item [&_li]:relative',
    '[&_li]:before:w-5 [&_li]:before:h-5 [&_li]:before:block',
    '[&_li]:before:absolute [&_li]:before:-left-6 [&_li]:before:top-0.5',
    '[&_li]:before:bg-list-star [&_li]:before:bg-cover',
    // 2nd level nested list styles
    '[&_ul>li>ul_li:last-child]:pb-0',
    '[&_ul>li>ul_li]:before:w-3 [&_ul>li>ul_li]:before:h-3',
    '[&_ul>li>ul_li]:before:top-1.5 [&_ul>li>ul_li]:before:-left-5',
  ),
  title: 'text-center mb-8 md:mb-12 mt-16 md:mt-32 pt-6',
}

export const Pricing = ({ blok }: PricingProps) => (
  <div className={styles.root}>
    <div className={styles.background} />
    <Typography variant="h1" as="h2" className={styles.title}>
      {blok.title}
    </Typography>
    <Card className={styles.card}>
      {blok.content.map((innerBlok) => (
        <DynamicComponent key={innerBlok._uid} blok={innerBlok} />
      ))}
    </Card>
  </div>
)
