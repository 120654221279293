import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import Image from 'next/image'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { LandingPageInfoBlockStoryblok } from '@electro/consumersite/types/generated/storyblok'

interface LandingPageInfoBlockProps {
  blok: LandingPageInfoBlockStoryblok
}

const styles = {
  root: 'flex gap-4',
  content: '[&_h3]:text-primary [&_h3]:font-normal [&_h3]:mb-0 [&_h3]:pb-0 flex-grow',
  image: {
    wrapper: 'block relative w-12 h-12 mb-8 flex-grow-0 flex-shrink-0',
    img: 'mb-4 object-contain w-12 h-12',
  },
}

export const LandingPageInfoBlock = ({ blok }: LandingPageInfoBlockProps) => (
  <div className={styles.root}>
    {blok.icon ? (
      <div className={styles.image.wrapper}>
        <Image
          fill
          src={getCmsAssetUrl(blok.icon.filename)}
          alt={blok.icon.alt}
          className={styles.image.img}
        />
      </div>
    ) : null}
    {blok?.content ? <div className={styles.content}>{render(blok.content)}</div> : null}
  </div>
)
