/* eslint-disable no-underscore-dangle */
import { Details } from '@electro/shared-ui-components'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'

import { FaqStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

interface FaqProps {
  blok: FaqStoryblok
}

export const Faq = ({ blok }: FaqProps) => (
  <Details.Item>
    <Details.Title onClick={() => GTM.openFAQcomponent({ title: blok.title })}>
      {blok.title}
    </Details.Title>
    <Details.Content>
      {blok?.block?.length > 0 && blok.block.map((b) => <DynamicComponent key={b._uid} blok={b} />)}
    </Details.Content>
  </Details.Item>
)
