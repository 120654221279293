import { HomepageBorderedImagesBottomStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import { Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { BorderedImage } from './components/BorderedImage'

interface HomepageBorderedImagesBottomProps {
  blok: HomepageBorderedImagesBottomStoryblok
}

// Style changes in this file will likely need to be reflected in BorderedImagesTop.tsx
const styles = {
  root: 'flex flex-col-reverse md:flex-row gap-4 lg:gap-x-10 my-32',
  right: 'flex flex-col gap-4 pr-8 lg:pr-0 pl-8 md:pl-0 basis-7/12 lg:basis-6/12 justify-around',
  text: 'flex flex-col gap-y-1 w-full text-end pl-14',
}

export const BorderedImagesBottom = ({ blok }: HomepageBorderedImagesBottomProps) => {
  const { t } = useTranslation('common')

  return (
    <section className={styles.root}>
      <BorderedImage
        className="items-end pr-8 md:p-0 basis-6/12 lg:basis-4/12"
        borderClassName="p-6 -ml-4 pl-10 lg:p-14 lg:-ml-6 lg:pl-20"
        colour="Blue"
        caption={`.${t('common.charging_methods.in_car')}`}
        image={{
          filename: blok.inCarAppsImage.filename,
          alt: blok.inCarAppsImage.alt,
        }}
        modalContent={{ header: blok.inCarAppsModalHeader, body: blok.inCarAppsModalBody }}
      />

      <div className={styles.right}>
        <div className={styles.text}>
          <Typography variant="h2" as="h2" className="mb-3 text-primary">
            {blok.title}
          </Typography>
          <Typography variant="h3" as="h3" className="text-base lg:pl-32">
            {render(blok.textarea)}
          </Typography>
        </div>

        <BorderedImage
          className="items-start w-10/12"
          borderClassName="p-4 xl:p-6"
          colour="Pink"
          caption=".Android Auto & Apple CarPlay"
          image={{
            filename: blok.androidAutoAppleCarplayImage.filename,
            alt: blok.androidAutoAppleCarplayImage.alt,
          }}
          modalContent={{
            header: blok.androidAutoAppleCarplayModalHeader,
            body: blok.androidAutoAppleCarplayModalBody,
          }}
        />
      </div>
    </section>
  )
}
