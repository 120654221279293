import { AnchoredHeadingStoryblok } from '@electro/consumersite/types/generated/storyblok'

interface AnchoredHeadingProps {
  blok: AnchoredHeadingStoryblok
}

export const AnchoredHeading = ({ blok }: AnchoredHeadingProps) => (
  <div className="max-w-2xl mx-auto postContent relative" data-testid="anchored-heading">
    <div id={blok.anchorLink} className="absolute -top-18 pointer-events-none" aria-hidden="true" />
    {blok.variant === 'h2' ? <h2>{blok.text}</h2> : null}
    {blok.variant === 'h3' ? <h3>{blok.text}</h3> : null}
    {blok.variant === 'h4' ? <h4>{blok.text}</h4> : null}
  </div>
)
