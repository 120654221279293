import Image from 'next/image'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { BigTextStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { Typography } from '@electro/shared-ui-components'

interface SubtleStatBlockProps {
  blok: BigTextStoryblok
}

export const SubtleStatBlock = ({ blok }: SubtleStatBlockProps) => (
  <div
    data-testid="subtle-stat-block"
    className="border-l-4 border-l-secondary mx-0 md:mx-12 flex flex-col pl-4 mb-8 last:mb-0 md:mb-0"
  >
    {blok.icon ? (
      <div className="block relative w-16 h-16 mb-8">
        <Image
          fill
          src={getCmsAssetUrl(blok.icon.filename)}
          alt={blok.icon.alt}
          className="mb-4 object-contain w-16 h-16"
        />
      </div>
    ) : null}
    {blok.text ? (
      <Typography className="uppercase text-3xl font-normal text-primary">{blok.text}</Typography>
    ) : null}
    {blok.caption ? (
      <Typography className="w-44 sm:w-auto md:w-44 sm:pr-2 md:pr-0">{blok.caption}</Typography>
    ) : null}
  </div>
)
