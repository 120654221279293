import { ColumnImageLayoutStoryblok } from '@electro/consumersite/types/generated/storyblok'
import Image from 'next/image'

import { DynamicComponent } from '@electro/consumersite/src/storyblok/DynamicComponent'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { Typography } from '@electro/shared-ui-components'

interface ColumnImageLayoutProps {
  blok: ColumnImageLayoutStoryblok
}

export const ColumnImageLayout = ({ blok }: ColumnImageLayoutProps) => {
  const dimensions = getStoryBlokImageDimensions(blok.image.filename)

  return (
    <div className="flex flex-col md:grid grid-cols-12  max-w-md md:max-w-6xl mx-auto px-8 py-16">
      {blok.headline ? (
        <Typography
          variant="h2"
          as="h2"
          className="order-1 text-center col-span-12 pb-4 md:pb-8 text-xl md:text-3xl lg:text-3xl !text-primary"
        >
          {blok.headline}
        </Typography>
      ) : null}
      <div className="order-3 md:order-2 col-span-5 flex flex-col gap-8">
        {blok?.columnBlocks?.map((singleBlok) => (
          // eslint-disable-next-line no-underscore-dangle
          <DynamicComponent blok={singleBlok} key={singleBlok._uid} />
        ))}
      </div>
      {blok.image ? (
        <div className="col-span-6 col-start-7 order-2 md:order-3 mb-8 md:mb-0">
          <Image
            src={getCmsAssetUrl(blok.image.filename)}
            width={dimensions.width}
            height={dimensions.height}
            alt={blok.image.alt}
          />
        </div>
      ) : null}
    </div>
  )
}
