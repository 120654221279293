import Link from 'next/link'
import { tw } from '@electro/shared/utils/tailwind-merge'
import clsx from 'clsx'
import { Button, Typography } from '@electro/shared-ui-components'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'

export const CtaButton = ({ blok }) => {
  const assetLink = blok?.assetDownload?.filename
    ? getCmsAssetUrl(blok?.assetDownload?.filename)
    : null
  return (
    <div
      className={tw({
        'my-8': !blok.marginsDisabled,
        'm-auto w-fit': blok.centered,
        [blok.className]: blok.className,
      })}
    >
      <Link href={assetLink || blok.href} className="no-underline">
        <Button
          variant={blok?.variant}
          size={blok.size}
          fullWidth={blok.fullWidth}
          data-testid="sb-ctaButton"
        >
          {blok.text}
        </Button>
      </Link>
      {blok.caption ? (
        <Typography
          variant="small"
          as="p"
          className={clsx({
            'm-auto w-fit mt-4 mb-12 opacity-80': blok.centered,
          })}
        >
          {blok.caption}
        </Typography>
      ) : null}
    </div>
  )
}
