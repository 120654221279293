/* eslint-disable no-underscore-dangle */
import { DynamicComponent } from '../../DynamicComponent'

export const ContentGroup = ({ blok }) => (
  <div data-testid="contentGroup">
    {blok.content.map((elem) => (
      <DynamicComponent blok={elem} key={elem._uid} />
    ))}
  </div>
)
