import { tw } from '@electro/shared/utils/tailwind-merge'
import { PageTitleStoryblok } from '@electro/consumersite/types/generated/storyblok'

interface PageTitleProps {
  blok: PageTitleStoryblok
}

const styles = {
  root: 'max-w-6xl w-full mx-auto px-2 pt-8 pb-2 sm:pb-4 relative ',
  display: 'text-3xl sm:text-5xl !text-white',
  textCentre: 'text-center',
}

export const PageTitle = ({ blok }: PageTitleProps) => (
  <h1
    className={tw({
      [styles.root]: true,
      [styles.display]: !!blok?.displayTitle,
      [styles.textCentre]: !!blok?.textCentre,
    })}
  >
    {blok.text}
  </h1>
)
