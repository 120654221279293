import Link from 'next/link'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Button, Card } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import { FleetsPricingCardStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

const styles = {
  title: 'text-3xl font-semibold text-center pt-10 md:pt-16 mb-10 md:text-5xl leading-snug',
  root: 'pb-16 px-8',
  card: {
    root: 'border-primary border-4 text-white bg-base/40 overflow-hidden rounded-4xl max-w-5xl lg:mx-auto',
    banner: {
      root: 'flex justify-center items-center gap-x-2 h-14 w-full bg-secondary px-2 xs:px-8',
      icon: 'w-4 xs:w-6 shrink-0',
      text: tw('text-xs sm:text-base sm:text-white font-semibold text-center mb-0'),
    },
    header: {
      root: 'flex justify-between my-10 mx-4 md:mx-8 lg:mx-16',
      priceAndQuote: {
        root: 'flex flex-col justify-between gap-y-6',
        priceInfo: {
          root: 'flex flex-col lg:flex-row gap-x-4 text-5xl font-semibold',
          priceText: 'mt-auto mb-0 text-2xl font-normal text-tertiary-lavender',
        },
        quote: {
          root: 'relative bg-secondary-dark rounded-xl py-2 pl-3 pr-4 text-xl lg:text-2xl italic w-fit mb-3',
          tinyHook: 'w-8 absolute -bottom-4 left-8',
        },
      },
      image: 'w-36 sm:w-48 -mr-7 sm:mr-0',
    },
    benefitsList: {
      root: 'flex flex-col md:flex-row justify-between gap-6 mx-4 md:mx-8 lg:mx-16',
      list: tw(
        'w-full sm:w-[25rem]',
        '[&>h3]:text-2xl',
        '[&_ul]:flex [&_ul]:flex-col [&_ul]:justify-between [&_ul]:h-96 [&_ul]:xs:h-72',
        '[&_p]:text-lg [&_p]:text-tertiary-lavender [&_p]:leading-snug [&_p]:flex [&_p]:items-center',
        '[&_p]:before:content-[url("/images/fleets-pink-checkmark.svg")] [&_p]:before:mr-4',
      ),
    },
    button: 'block no-underline max-w-64 md:max-w-80 lg:max-w-120 my-10 mx-auto',
  },
}

interface FleetsPricingCardProps {
  blok: FleetsPricingCardStoryblok
}

export const FleetsPricingCard = ({ blok }: FleetsPricingCardProps) => {
  const animationRef = useRef(null)
  const [isAnimationRunning, setIsAnimationRunning] = useState<boolean>(false)

  const onAnimationHover = () => {
    if (!isAnimationRunning) {
      animationRef?.current?.goToAndPlay(0)
      setIsAnimationRunning(true)
      setTimeout(() => setIsAnimationRunning(false), 2500)
    }
  }

  return (
    <section data-testid="FleetsPricingCard" className={styles.root}>
      <h2 className={styles.title}>{blok.titleText}</h2>

      <Card className={styles.card.root} density="none">
        <span className={styles.card.banner.root}>
          <InformationCircleIcon className={styles.card.banner.icon} />
          <p className={styles.card.banner.text}>{blok.bannerText}</p>
        </span>

        <div className={styles.card.header.root}>
          <div className={styles.card.header.priceAndQuote.root}>
            <span className={styles.card.header.priceAndQuote.priceInfo.root}>
              {blok.price}
              <p className={styles.card.header.priceAndQuote.priceInfo.priceText}>
                {blok.priceText}
              </p>
            </span>

            <span className={styles.card.header.priceAndQuote.quote.root}>
              &quot;{blok.quoteText}&quot;
              <Image
                src="/images/quote-bubble-hook.svg"
                alt="A tiny hook for the quote bubble!"
                className={styles.card.header.priceAndQuote.quote.tinyHook}
                height={13}
                width={21}
                priority
              />
            </span>
          </div>

          <AnimatedComponent
            name="constantine-saving-money"
            getAnimationData={ANIMATIONS.constantineSavingMoney}
            lottieProps={{
              loop: false,
              autoPlay: false,
              lottieRef: animationRef,
              onMouseEnter: onAnimationHover,
              className: styles.card.header.image,
            }}
          />
        </div>

        <div className={styles.card.benefitsList.root}>
          <span className={styles.card.benefitsList.list}>{render(blok.managersBenefits)}</span>

          <span className={styles.card.benefitsList.list}>{render(blok.driversBenefits)}</span>
        </div>

        <Link href={blok.buttonLink.url} passHref className={styles.card.button}>
          <Button fullWidth>{blok.buttonText}</Button>
        </Link>
      </Card>
    </section>
  )
}
