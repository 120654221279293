/* eslint-disable no-underscore-dangle */
import { tw } from '@electro/shared/utils/tailwind-merge'
import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

import { PostImageStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { LazyImage } from '@electro/shared-ui-components'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

interface PostImageProps {
  blok: PostImageStoryblok
}

const POST_MAX_WIDTH = 672

const styles = {
  root: 'max-w-2xl w-full mx-auto relative',
  margin: 'pb-12 pt-8',
  image: 'rounded-2xl overflow-hidden',
  caption: 'w-full block text-left text-base-dark/50 text-sm mt-3',
  centered: 'mx-auto text-center',
}

interface GetImagePaddingArgs {
  x?: string | number
  y?: string | number
}

/**
 * getImagePadding()
 * mapping tailwind properties directly here to give us flexibility in the future.
 * T-Shirt sizes will bind us to specific attributes. These ints allow us to map the
 * values in storyblok without making further changes to the API Here.
 */
const getImagePadding = ({ x, y }: GetImagePaddingArgs): string => {
  const paddingMap = {
    60: {
      x: 'px-60',
      y: 'my-60',
    },
    44: {
      x: 'px-44',
      y: 'my-44',
    },
    32: {
      x: 'px-32',
      y: 'my-32',
    },
    24: {
      x: 'px-24',
      y: 'my-24',
    },
    16: {
      x: 'px-16',
      y: 'my-16',
    },
    8: {
      x: 'px-8',
      y: 'my-8',
    },
    4: {
      x: 'px-4',
      y: 'my-4',
    },
  }

  const paddingX = paddingMap[x]?.x || ''
  const paddingY = paddingMap[y]?.y || ''

  return `${paddingX} ${paddingY}`
}

export const PostImage = ({ blok }: PostImageProps) => {
  const dimensions = getStoryBlokImageDimensions(blok.image.filename)

  /**
   * When we load an image from storyblok we know its dimensions because
   * they are in the URL.
   * We are extracting these and ensuring that our images are not unnecessarily
   * massive and are constrained to the maximum post width
   */
  const constrainedDimensions =
    dimensions?.width > POST_MAX_WIDTH
      ? {
          width: POST_MAX_WIDTH,
          height: dimensions.height / (dimensions.width / POST_MAX_WIDTH),
        }
      : dimensions

  const padding = getImagePadding({
    x: blok.paddingX || null,
    y: blok.paddingY || null,
  })

  return blok.image.filename ? (
    <div
      className={tw({
        [styles.root]: true,
        [styles.margin]: !blok.disableMargins,
      })}
      aria-labelledby={`${blok._uid}-caption`}
    >
      <div
        role="figure"
        className={tw({
          [padding]: !!padding,
        })}
      >
        {blok.image.filename.includes('.svg') ? (
          <img
            className={tw({
              [styles.image]: true,
              [styles.centered]: blok.centered,
            })}
            src={`${getCmsAssetUrl(blok.image.filename)}/m/`}
            alt={blok.image.alt}
          />
        ) : (
          <LazyImage
            width={constrainedDimensions?.width}
            height={constrainedDimensions?.height}
            className={tw({
              [styles.image]: true,
              [styles.centered]: blok.centered,
            })}
            src={`${getCmsAssetUrl(blok.image.filename)}/m/`}
            alt={blok.image.alt}
          />
        )}

        {blok?.caption && (
          <div
            id={`${blok._uid}-caption`}
            className={tw({
              [styles.caption]: true,
              [styles.centered]: blok.centered,
            })}
          >
            {render(blok.caption)}
          </div>
        )}
      </div>
    </div>
  ) : null
}
