import { EmbedStoryblok } from '@electro/consumersite/types/generated/storyblok'

interface EmbedProps {
  blok: EmbedStoryblok
}
export const Embed = ({ blok }: EmbedProps) => (
  <div className="flex items-center justify-center pt-24 pb-24">
    <iframe
      data-testid="embed"
      src={blok.src}
      style={{ maxWidth: `${blok.width}px` || '100%', height: `${blok.height}px` || '100%' }}
      width="100%"
      height="100%"
      title={blok.title}
    />
  </div>
)
