import { tw } from '@electro/shared/utils/tailwind-merge'

import { PostGalleryLink } from '@electro/consumersite/src/storyblok/bloks'
import { FormattedPost } from '@electro/consumersite/src/storyblok/helpers/formatPostsForGallery'

interface Props {
  posts: FormattedPost[]
  currentCategorySlug?: string
  showConstantine?: boolean
}

const styles = {
  posts: {
    grid: tw(
      'grid grid-cols-1 gap-x-0 gap-y-6 mt-8 max-w-6xl mx-auto',
      'sm:gap-x-16 sm:grid-cols-2',
      'md:grid-cols-3',
      'xl:gap-x-32 xl:gap-y-12',
    ),
  },
}

export const PostGallery = ({ posts, currentCategorySlug, showConstantine }: Props) => (
  <div className={styles.posts.grid} data-testid="posts-gallery">
    {posts?.map((post, index) => {
      const isLastItem = index === posts.length - 1
      return (
        <PostGalleryLink
          key={post.id}
          post={post}
          currentCategorySlug={currentCategorySlug || post?.categories?.[0]}
          showConstantine={showConstantine && isLastItem}
        />
      )
    })}
  </div>
)
