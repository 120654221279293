import Link from 'next/link'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { AppleIcon, GooglePlayIcon } from '@electro/icons/24/logos'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import {
  useDeviceInformation,
  OperatingSystemEnum,
} from '@electro/consumersite/src/hooks/useDeviceInformation'

interface AppStoreButtonProps {
  ios: {
    subText: string
    titleText: string
  }
  android: {
    subText: string
    titleText: string
  }
}

const styles = {
  root: 'flex w-fit border border-white rounded-md items-center py-1 px-2 no-underline',
  svg: 'text-white size-6 mr-2',
  text: {
    root: 'flex flex-col text-start leading-none [&>p]:mb-0',
    subText: 'text-[9px]',
  },
}

const APP_STORE_DEFAULTS = [
  {
    os: OperatingSystemEnum.IOS,
    svg: <AppleIcon className={styles.svg} />,
    href: 'https://apps.apple.com/LOCALE/app/octopus-electric-universe/id1535444463',
    onClick: () => GTM.openAppleStore(),
  },
  {
    os: OperatingSystemEnum.ANDROID,
    svg: <GooglePlayIcon className={styles.svg} />,
    href: 'https://play.google.com/store/apps/details?id=energy.octopus.electricjuice.android&hl=LOCALE',
    onClick: () => GTM.openGooglePlayStore(),
  },
]

export const AppStoreButtons = ({ ios, android }: AppStoreButtonProps) => {
  const router = useRouter()

  const { userAgent } = useDeviceInformation()

  const filteredAppStoreOptions = useMemo(() => {
    const appStoreOptions = [
      { ...android, ...APP_STORE_DEFAULTS[1] },
      { ...ios, ...APP_STORE_DEFAULTS[0] },
    ]

    if (!APP_STORE_DEFAULTS.some(({ os }) => os === userAgent?.deviceOS)) return appStoreOptions

    return appStoreOptions.filter(({ os }) => os === userAgent?.deviceOS)
  }, [userAgent, android, ios])

  return (
    <>
      {filteredAppStoreOptions.map(({ os, onClick, href, svg, subText, titleText }) => (
        <Link
          key={os}
          data-testid={`app-store-button-${os}`}
          target="_blank"
          onClick={onClick}
          className={styles.root}
          href={
            os === OperatingSystemEnum.IOS
              ? href.replace('LOCALE', router.locale.slice(-2)) // Apple uses the region code
              : href.replace('LOCALE', router.locale.slice(0, 2)) // Google uses the language code
          }
        >
          {svg}
          <div className={styles.text.root}>
            <p className={styles.text.subText}>{subText}</p>
            <p>{titleText}</p>
          </div>
        </Link>
      ))}
    </>
  )
}
