import { FrequentlyAskedQuestionsSectionStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'
import { Details } from '@electro/shared-ui-components'

interface FaqsSectionProps {
  blok: FrequentlyAskedQuestionsSectionStoryblok
}

export const FaqsSection = ({ blok }: FaqsSectionProps) => (
  <div className="relative">
    <section
      data-testid="faqs"
      id="faqs"
      className="max-w-3xl w-full mx-auto pt-16 pb-16 px-2 relative"
    >
      <div className="relative z-10">
        <h2 id="faqs" className="text-center text-2xl mb-6">
          {blok.title}
        </h2>
        <Details>
          {blok.faqs.map((faq) => (
            // eslint-disable-next-line no-underscore-dangle
            <DynamicComponent blok={faq} key={faq._uid} />
          ))}
        </Details>
      </div>
    </section>
  </div>
)
