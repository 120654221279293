/* eslint-disable no-underscore-dangle */
import { DynamicComponent } from '@electro/consumersite/src/storyblok'
import {
  OperatorGridStoryblok,
  OperatorStoryblok,
} from '@electro/consumersite/types/generated/storyblok'

interface OperatorGridProps {
  blok: OperatorGridStoryblok
}

export const OperatorGrid = ({ blok }: OperatorGridProps) => (
  <div data-testid="operator-grid" className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-2">
    {blok?.operators?.map((operator: OperatorStoryblok) => (
      <DynamicComponent blok={operator} key={operator._uid} />
    ))}
  </div>
)
