import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'
import Image from 'next/image'
import { Typography } from '@electro/shared-ui-components'
import {
  CtaButtonStoryblok,
  FleetsLandingPageHeaderStoryblok,
} from '@electro/consumersite/types/generated/storyblok'
import { DynamicComponent } from '@electro/consumersite/src/storyblok/DynamicComponent'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface HeaderProps {
  blok: FleetsLandingPageHeaderStoryblok
}

const styles = {
  section: {
    default: 'relative flex flex-col items-center gap-4 my-16  min-h-80 px-5 mx-auto max-w-7xl ',
    md: 'md:flex-row',
    lg: 'lg:mb-40 lg:flex-row lg:w-screen',
    xl: 'xl:flex xl:items-center xl:mx-auto',
  },
  headlineContainer: {
    default: 'order-2',
    md: 'md:order-1 md:w-4/5',
    lg: 'lg:w-2/3',
    xl: 'xl:w-2/3',
  },
  headline: {
    'bold-pink-uppercase':
      'relative z-0 !text-primary uppercase text-3xl sm:text-4xl lg:text-5xl xl:text-7xl',
    'large-pink-sentence':
      'relative z-10 !text-primary normal-case text-4xl md:text-4xl lg:text-6xl text-center md:text-left',
    'bold-white-uppercase':
      'relative z-0 !text-white uppercase text-3xl sm:text-4xl lg:text-5xl xl:text-7xl',
    'large-white-sentence': 'relative z-0 !text-white normal-case text-3xl sm:text-4xl lg:text-6xl',
  },
  content: {
    default: 'text-3xl z-12 !leading-tight text-center',
    md: 'md:text-3xl md:w-9/10 md:w-3/5 md:text-left',
    lg: 'lg:text-4xl lg:w-4/5',
    xl: 'xl:w-4/5',
  },
  buttonContainer: {
    default: 'flex flex-col gap-4 mt-4 md:items-start',
    md: 'md:mt-8 flex-col',
    lg: 'lg:flex-row lg:items-center',
  },
  imageContainer: {
    default: 'order-1  flex w-full',
    md: 'md:order-2 md:absolute md:top-14  md:w-1/2 md:right-5',
  },
}

export const FleetsLandingPageHeader = ({ blok }: HeaderProps) => (
  <section
    data-testid="landing-page-main-banner"
    className={tw({
      [styles.section.default]: true,
      [styles.section.md]: true,
      [styles.section.lg]: true,
      [styles.section.xl]: true,
    })}
  >
    <div
      className={tw({
        [styles.headlineContainer.default]: true,
        [styles.headlineContainer.md]: true,
        [styles.headlineContainer.lg]: true,
        [styles.headlineContainer.xl]: true,
      })}
    >
      {blok.Headline ? (
        <Typography variant="h1" as="h1" className={styles.headline[blok.headlineStyle]}>
          {blok.Headline}
        </Typography>
      ) : null}

      {blok.content ? (
        <Typography
          className={tw({
            [styles.content.default]: true,
            [styles.content.md]: true,
            [styles.content.lg]: true,
            [styles.content.xl]: true,
          })}
        >
          {render(blok.content)}
        </Typography>
      ) : null}

      {blok?.buttons ? (
        <div
          className={tw({
            [styles.buttonContainer.default]: true,
            [styles.buttonContainer.md]: true,
            [styles.buttonContainer.lg]: true,
          })}
        >
          {blok?.buttons?.map((button: CtaButtonStoryblok) => (
            // eslint-disable-next-line no-underscore-dangle
            <DynamicComponent blok={button} key={button._uid} />
          ))}
        </div>
      ) : null}
    </div>
    <div
      className={tw({
        [styles.imageContainer.default]: true,
        [styles.imageContainer.md]: true,
      })}
    >
      <Image
        src="/images/landing-page-heading-fleets-platforms.png"
        width="1036"
        height="477"
        alt="fleets platforms image"
        unoptimized
      />
    </div>
  </section>
)
