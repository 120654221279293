import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'
import { useState } from 'react'
import { Typography } from '@electro/shared-ui-components'
import { RichtextStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '../../../helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '../../../helpers/getStoryBlokImageDimensions'
import { ImageInfoModal } from './ImageInfoModal'

interface BorderedImageProps {
  className?: string
  borderClassName?: string
  frosted?: boolean
  colour: 'Pink' | 'Blue'
  caption: string
  image: {
    filename: string
    alt: string
  }
  modalContent?: {
    header: string
    body: RichtextStoryblok
  }
}

export const BorderedImage = ({
  className,
  borderClassName,
  frosted,
  colour,
  caption,
  image,
  modalContent,
}: BorderedImageProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const imageDimensions = getStoryBlokImageDimensions(image.filename)

  const colourStyles = {
    Pink: {
      text: 'text-primary',
      border: 'border-primary',
      background: 'bg-primary/30',
    },
    Blue: {
      text: 'text-secondary-light',
      border: 'border-secondary-light',
      background: 'bg-secondary-light/30',
    },
  }

  return (
    <>
      <button
        disabled={!modalContent}
        onClick={() => setShowModal(!showModal)}
        className={tw('flex flex-col z-10', className)}
      >
        <Typography variant="h2" as="h2" className={colourStyles[colour].text}>
          {caption}
        </Typography>
        <div
          className={tw(`border-2 ${colourStyles[colour].border} rounded-2xl`, borderClassName, {
            [`${colourStyles[colour].background} backdrop-blur-xl`]: frosted,
          })}
        >
          <Image
            src={`${getCmsAssetUrl(image.filename)}/m/`}
            alt={image.alt}
            width={imageDimensions?.width}
            height={imageDimensions?.height}
            className="pointer-events-none"
          />
        </div>
      </button>

      {modalContent && (
        <ImageInfoModal
          modalVisible={showModal}
          setModalVisible={() => setShowModal(!showModal)}
          content={{ header: modalContent.header, body: modalContent.body }}
        />
      )}
    </>
  )
}
