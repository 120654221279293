import Image from 'next/image'
import { useEffect } from 'react'
import { Textfit } from 'react-textfit'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'
import { BigTextStoryblok } from '@electro/consumersite/types/generated/storyblok'

/**
 * Text in this component will always stretch to fit its container.
 */

interface BigTextStatBlockProps {
  blok: BigTextStoryblok
}

export const BigTextStatBlock = ({ blok }: BigTextStatBlockProps) => {
  /**
   * SSR in next means that the font size is not calculated by TextFit on
   * first render. Triggering a resize event is a workaround that
   * makes sure the TextFit component runs and fits the text to
   * the correct size!
   */
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 10)
  }, [])

  const iconDimensions = blok.icon ? getStoryBlokImageDimensions(blok.icon.filename) : null

  return (
    <div
      data-testid="big-text-stat-block"
      className="text-center bg-white h-full mb-2 flex flex-col justify-center w-full px-10 lg:px-18 rounded-3xl text-secondary font-medium border-2 border-secondary py-2 "
    >
      {blok.icon ? (
        // Svg do not have width and height attributes
        // so we need to add some defaults
        <Image
          width={iconDimensions?.height || 100}
          height={iconDimensions?.width || 100}
          src={getCmsAssetUrl(blok.icon.filename)}
          alt={blok.icon.alt}
          className="mx-auto mt-4"
        />
      ) : null}

      <Textfit key="2" mode="single">
        {blok.text}
      </Textfit>

      {blok.caption ? (
        <div className="font-light text-2xl mb-4" data-test-id="big-text-caption">
          {blok.caption}
        </div>
      ) : null}
    </div>
  )
}
