import { TopNavigation, Footer, SocialSharing } from '@electro/consumersite/src/components'
import { Head } from '@electro/shared-ui-components'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'
import { PageStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { useRouter } from 'next/router'

interface PageLayoutProps {
  blok: PageStoryblok
}

export const PageLayout = ({ blok }: PageLayoutProps) => {
  const { asPath } = useRouter()
  const isBusinessRoute = asPath.includes('/business')
  const openGraphImage = `https://electroverse.octopus.energy/images/og-image-${isBusinessRoute ? 'fleets' : 'default'}.png`

  return (
    <>
      <Head
        title={blok.pageTitle}
        description={blok.pageDescription}
        image={openGraphImage}
        showAppBanner
      >
        {blok.noIndex && <meta name="robots" content="noindex" />}
      </Head>
      <div className="relative bg-base bg-gradient-mesh-lg overflow-hidden">
        <div className="flex flex-col">
          <TopNavigation />
          <main className="flex-grow relative px-0 lg:px-0" data-testid="page-template-main">
            <div className="h-20" /> {/* Adds TopNav space without distorting button ripple */}
            {blok.body
              ? // eslint-disable-next-line no-underscore-dangle
                blok.body.map((blk) => <DynamicComponent blok={blk} key={blk._uid} />)
              : null}
            {blok.showSocialSharing && <SocialSharing pageDescription={blok.pageDescription} />}
          </main>
          <Footer />
        </div>
      </div>
    </>
  )
}
