import Image from 'next/image'

import { BorderedImageStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { Typography } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface BorderedImageProps {
  blok: BorderedImageStoryblok
}

const styles = {
  root: 'flex flex-col',
  text: 'flex flex-col gap-y-1 w-full text-end pl-14',
  align: {
    right: 'text-left pl-8 sm:pl-0',
    left: 'text-right pr-8 sm:pr-0',
  },

  topMargin: 'sm:mt-44',
  image: {
    left: 'rounded-bl-none rounded-tl-none border-l-0 pl-0',
    right: 'rounded-br-none rounded-tr-none border-r-0 pr-0',
  },
}

const colorStyles = {
  Pink: {
    text: '!text-primary mb-0',
    border: 'border-primary',
    background: 'bg-primary/30',
  },
  Blue: {
    text: '!text-secondary-light mb-0',
    border: 'border-secondary-light',
    background: 'bg-secondary-light/30',
  },
}

export const BorderedImage = ({ blok }: BorderedImageProps) => {
  const imageDimensions = getStoryBlokImageDimensions(blok.image.filename)

  const leftAligned = blok.align === 'left'
  const rightAligned = blok.align === 'right'

  return (
    <section
      className={tw({
        [styles.root]: true,
        [styles.align.left]: leftAligned,
        [styles.align.right]: rightAligned,
        [styles.topMargin]: blok.topMargin,
      })}
    >
      {blok.title ? (
        <Typography variant="h2" as="h2" className={tw(colorStyles[blok.color].text, 'mb-2')}>
          {blok.title}
        </Typography>
      ) : null}

      {blok.caption ? (
        <Typography variant="body" as="p" className="mb-2 mt-2">
          {blok.caption}
        </Typography>
      ) : null}
      {blok.image ? (
        <div
          className={tw(`border-2 ${colorStyles[blok.color].border} rounded-2xl p-8`, {
            [styles.image.left]: leftAligned,
            [styles.image.right]: rightAligned,
          })}
        >
          <Image
            src={`${getCmsAssetUrl(blok.image.filename)}/m/${imageDimensions.width * 2}x0`}
            alt={blok.image.alt}
            width={imageDimensions?.width}
            height={imageDimensions?.height}
          />
        </div>
      ) : null}
    </section>
  )
}
