import Link from 'next/link'
import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Button, Typography } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import { HeroV2Storyblok } from '@electro/consumersite/types/generated/storyblok'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'

const styles = {
  zapBlackHole: tw(
    'z-0 pointer-events-none max-w-[448px]',
    'sm:absolute sm:-right-48 sm:top-20',
    'md:-right-60 md:top-16',
    'lg:top-36 lg:-right-96',
  ),
}

interface HeroProps {
  blok: HeroV2Storyblok
}

export const HeroV2 = ({ blok }: HeroProps) => {
  const activeHolidaySeason = getActiveHolidaySeason()

  return (
    <section
      data-testid="hero-banner-v2"
      className={tw(
        'pb-16 max-w-6xl mx-auto relative px-8 ',
        'sm:pb-56 sm:pt-12',
        'md:pt-20 md:pb-20',
        'xl:px-0',
      )}
    >
      <div className="relative sm:max-w-sm lg:max-w-md">
        <AnimatedComponent
          name="zap-black-hole"
          getAnimationData={activeHolidaySeason === 'christmas' && ANIMATIONS.zapBlackHoleChristmas}
          lottieProps={{ className: styles.zapBlackHole }}
          imageProps={{
            src: getSeasonalImagePath('zap-black-hole', activeHolidaySeason),
            alt: 'Zap and Constantine entering the electroverse through a portal',
            className: styles.zapBlackHole,
            priority: true,
            quality: 50,
            height: 519,
            width: 498,
          }}
        />
        <Typography variant="display" as="h1" className="relative z-0 select-none">
          {blok.headline}
        </Typography>
        <Typography className="relative z-20 sm:pr-32 lg:pr-14">{blok.subHeader}</Typography>
        {blok.buttonText ? (
          <Link href={blok.buttonHref || '/sign-up/magic'} className="no-underline max-w-max">
            <Button data-testid="hpHeroCta" className="mt-8">
              {blok.buttonText}
            </Button>
          </Link>
        ) : null}
      </div>
      <div
        className={tw(
          'absolute top-0 hidden right-2',
          'md:w-1/2 md:right-2 md:top-0 md:block',
          'lg:w-120 lg:right-8 lg:top-6',
          'xl:max-w-xl  xl:right-0',
        )}
      >
        <Image
          priority
          width={580}
          height={783}
          alt="screenshot of the Octopus Electroverse app next to an RFID card"
          src="/images/electroverse-screen-and-rfid-card.png"
          className="pointer-events-none"
        />
      </div>
    </section>
  )
}
