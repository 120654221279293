/* eslint-disable no-underscore-dangle */
import { LargeTextHeadlineStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { Typography } from '@electro/shared-ui-components'
import { DynamicComponent } from '@electro/consumersite/src/storyblok'

interface LargeTextHeadlineProps {
  blok: LargeTextHeadlineStoryblok
}

const styles = {
  root: 'max-w-sm md:max-w-2xl mx-auto text-center px-4',
  ctaButtons: 'flex flex-col md:flex-row gap-0 items-center justify-center md:gap-4',
  ctaButtonWrapper: 'flex-shrink-0 md:mt-0',
}

export const LargeTextHeadline = ({ blok }: LargeTextHeadlineProps) => (
  <>
    <div className={styles.root}>
      <Typography variant="display">{blok.headline}</Typography>
    </div>
    {blok.ctas.length > 0 ? (
      <div className={styles.ctaButtons}>
        {blok?.ctas?.map((cta) => (
          <div key={cta._uid} className={styles.ctaButtonWrapper}>
            <DynamicComponent blok={cta} />
          </div>
        ))}
      </div>
    ) : null}
  </>
)
