import { render } from '@electro/consumersite/src/storyblok/helpers/storyBlokRichTextRenderer'

import { LazyImage, Typography } from '@electro/shared-ui-components'
import { ProductHowItWorksStoryblok } from '@electro/consumersite/types/generated/storyblok'
import { getCmsAssetUrl } from '@electro/consumersite/src/storyblok/helpers/getCmsAssetUrl'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { getStoryBlokImageDimensions } from '@electro/consumersite/src/storyblok/helpers/getStoryBlokImageDimensions'

interface HowItWorksProps {
  blok: ProductHowItWorksStoryblok
}

const styles = {
  root: tw(
    'w-full mx-auto px-4 pb-8 pt-0 mb-8 max-w-md',
    'flex flex-col space-between items-center',
    'sm:pt-12 sm:pb-12 sm:gap-5',
    'md:flex-row sm:pt-8  md:gap-20 md:max-w-6xl',
    'xl:gap-40',
  ),
  content: {
    root: 'sm:items-center sm:justify-center order-2 text-left px-4',
    largeHeadlines: '[&_h2]:text-primary [&_h2]:text-xl md:[&_h2]:text-3xl',
  },
  image: {
    root: 'mx-auto sm:w-auto flex-shrink-0 mb-8 sm:mb-0  sm:mx-0',
    /**
     * The classes 'image-left' and 'image-right' are defined in Storyblok,
     * do not change them here without also changing them there.
     */
    'image-left': 'order-1 md:order-none',
    'image-right': 'order-1 md:order-3',
  },
}

export const HowItWorks = ({ blok }: HowItWorksProps) => {
  const dimensions = getStoryBlokImageDimensions(blok.image.filename)

  return (
    <section data-testid="single-image-plus-text" className={styles.root}>
      <Typography
        data-testid={blok.align}
        className={tw({
          [styles.content.root]: true,
          [styles.content.largeHeadlines]: blok.largeHeadlines,
        })}
      >
        {render(blok.body)}
      </Typography>
      <div className={tw(styles.image.root, styles.image[blok.align])}>
        <LazyImage
          className="drop-shadow-2xl"
          src={`${getCmsAssetUrl(blok.image.filename)}/m/${dimensions.width}x0`}
          alt={blok.image.alt}
          width={dimensions.width / 2}
          height={dimensions.height / 2}
        />
      </div>
    </section>
  )
}
